import '../css'

const checkRegistration = function() {
  $.ajax({type: 'GET', dataType: 'script', url: document.location.href})
}

$(document).on('check-registration', function() {
  var bar = $('.progress-bar')
  var progress = parseInt(bar.attr('style').match(/width: (\d+)%/)[1])

  if (progress == 100) {
    bar.removeClass('active').addClass('progress-bar-danger')
    $('#error').addClass('text-danger').text('There was a problem loading your information from our records. Please try again later.')
  }
  else {
    setTimeout(function() {
      bar.attr('style', `width: ${progress + 10}%;`)
      checkRegistration()
    }, 1000)
  }
})

$(function() {
  if ($('#loading').length > 0)
    checkRegistration()
})
