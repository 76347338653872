import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer'

$(function() {
  const mapContainer = document.getElementById('map')
  if (mapContainer) {
    const loader = new Loader({ apiKey: mapContainer.getAttribute('data-api-key'), version: 'quarterly' })

    Promise.all([
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
      loader.importLibrary('core'),
      fetch(location.href, { headers: { 'Accept': 'application/json' } }).then(resp => resp.json())
    ]).then(([{ Map, InfoWindow }, { Marker }, { LatLngBounds }, json]) => {
      const map = new Map(mapContainer)
      const bounds = new LatLngBounds()

      const markers = json.map(({ latitude, longitude, names }) => {
        let marker = new Marker({ position: { lat: latitude, lng: longitude }, map })

        marker.addListener('click', () => {
          new InfoWindow({ content: names }).open({ anchor: marker, map, shouldFocus: false })
        })

        bounds.extend(marker.getPosition())
        return marker
      })

      map.fitBounds(bounds)
      new MarkerClusterer({ map, markers, algorithm: new SuperClusterAlgorithm({ radius: 100 }) })
    })
  }
})
