const transferChoice = (type) => $(`#registration_transfer_${type}_choice_id`)

const setSecondChoice = function() {
  var el = transferChoice('second')
  var firstVal = transferChoice('first').val()

  el.attr('disabled', firstVal == '')
  el.children().removeAttr('disabled')

  if (firstVal == '') {
    el.val('')
  } else {
    if (el.val() == firstVal) el.val('')
    el.children(`option[value="${firstVal}"]`).attr('disabled', true)
  }

  setThirdChoice()
}

const setThirdChoice = function() {
  var el = transferChoice('third')
  var firstVal = transferChoice('first').val()
  var secondVal = transferChoice('second').val()

  el.attr('disabled', secondVal == '')
  el.children().removeAttr('disabled')

  if (secondVal == '') {
    el.val('')
  } else {
    if (el.val() == secondVal) el.val('')
    el.children(`option[value="${secondVal}"]`).attr('disabled', true)
  }

  if (firstVal != '') {
    if (el.val() == firstVal) el.val('')
    el.children(`option[value="${firstVal}"]`).attr('disabled', true)
  }
}

$(document).on('change', '#registration_transfer_first_choice_id', setSecondChoice)
$(document).on('change', '#registration_transfer_second_choice_id', setThirdChoice)
$(setSecondChoice)
