import '../css'
// import 'confirm'
import jstz from 'jstz'
import '@nathanvda/cocoon'

// $.fn.twitter_bootstrap_confirmbox.defaults = {
//   fade: false,
//   title: null,
//   cancel: 'Cancel',
//   cancel_class: 'btn btn-default',
//   proceed: 'Confirm',
//   proceed_class: 'btn btn-primary',
//   modal_class: ''
// }

$(function() {
  if (!$('meta[name="timezone"]').attr('content')) {
    var timezone = jstz.determine()
    $.post('/timezone', { _method: 'put', timezone: timezone.name() })
  }
})
