import ujs from '@rails/ujs'
ujs.start()

import './globals/jquery'

import 'bootstrap-sass'
import '@fortawesome/fontawesome-free/css/all.css'

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
select2(window, $)

import '#/layouts/js'
import '#/accounts/css'
import '$/admin/app/views'
import '$/info/app/views'
import '$/register/app/views'
