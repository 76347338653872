const toggleCountryDivisions = function(speed) {
  var country = $('form.registration.contact #registration_country_id option:selected').text()

  switch(country) {
  case 'United States':
    $('.states select').removeAttr('disabled')
    $('.provinces select').attr('disabled', true)
    $('.states').show(speed)
    $('.provinces').hide(speed)
    break
  case 'Canada':
    $('.states select').attr('disabled')
    $('.provinces select').removeAttr('disabled', true)
    $('.states').hide(speed)
    $('.provinces').show(speed)
    break
  default:
    $('.states select').removeAttr('disabled')
    $('.provinces select').attr('disabled', true)
    $('.states, .provinces').hide(speed)
    $('.states select').val('').trigger('change')
  }
}

$(toggleCountryDivisions)
$(document).on('change', 'form.registration.contact .registration_country select', function() {
  toggleCountryDivisions('slow')
})
