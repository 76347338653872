$(document).on('change', '.bulk-update select', function() {
  if ($('.bulk-update select:not([value=""])').length > 0) {
    $(this).parents('.form-group').append('<i class="fa fa-spinner fa-spin"></i>')
    $(this).parents('form').find('input[type="submit"]').trigger('click')
  }
})

$(document).on('change', 'select[name="email"]', function() {
  if ($(this).val() != '')
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: `/admin/transfers/email?site=${$(this).val()}`
    })
})

$(function() {
  $('.updates-container').css({ height: $('.updates').outerHeight() })
  $('.updates').affix({
    offset: {
      top: function() { return $('.updates-container').offset().top }
    }
  })
})

$(document).on('shown.bs.modal', '#email-modal', function() {
  document.querySelector('#email-modal textarea').select()
})

$(document).on('change', '#change-order', function() {
  window.location.href = `/admin/transfers?order=${$(this).val()}`
})
