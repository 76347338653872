const verifyBudget = function() {
  if ($('#registration_total_funds').val() == '0' && $('#registration_total_expenses').val() == '0')
    $('#registration_total_funds, #registration_total_expenses').parents('div.form-group').removeClass('has-success has-error')
  else {
    if (parseInt($('#registration_total_funds').val()) >= parseInt($('#registration_total_expenses').val()))
      $('#registration_total_funds, #registration_total_expenses').parents('div.form-group').removeClass('has-error').addClass('has-success')
    else
      $('#registration_total_funds, #registration_total_expenses').parents('div.form-group').removeClass('has-success').addClass('has-error')
  }
}

const cleanValue = function(val) {
  return parseInt(val.replace(/\D/g, ''))
}

const sumInputs = function(target) {
  var sum = 0
  $(target).each(function(i, el) {
    sum += cleanValue($(el).val()) || 0
  })

  return sum
}

$(document).on('keyup', 'form.registration.budget .funds', function() {
  $('#registration_total_funds').val(sumInputs('form.registration.budget .funds'))
  verifyBudget()
})

$(document).on('keyup', 'form.registration.budget .expenses', function() {
  $('#registration_total_expenses').val(sumInputs('form.registration.budget .expenses'))
  verifyBudget()
})

$(document).on('blur', 'form.registration.budget .funds, form.registration.budget .expenses', function() {
  $(this).val(cleanValue($(this).val()) || '')
})

$(verifyBudget)
