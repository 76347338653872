import 'bootstrap-3-typeahead'
import './assigned'
import './budget'
import './choir'
import './contact'
import './personal'
import './where'
import './transfer'
import '../css'

$(function() {
  $('.typeahead').typeahead({
    items: 'all',
    minLength: 0,
    autoSelect: false,
    showHintOnFocus: true,
    source: function(query, process) {
      return process(JSON.parse($('.typeahead').attr('data-source')))
    }
  })
})

$(document).on('change', 'form.registration', function() {
  setTimeout(function(form) {
    if (form.data('disableAutoSave')) return
    // Hijack rails' ujs driver
    form.attr('data-remote', 'true')
    form.find('[type="submit"]').trigger('click')
  } , 250, $(this))
})

$(document).on('ajax:complete', 'form.registration', function() {
  $(this).removeAttr('data-remote')
})

$(document).on('submit', 'button[type="submit"]', function() {
  $(this).parents('form.registration').data('disableAutoSave', true)
})
