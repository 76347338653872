import '../css'
import URI from 'urijs'

const applyScopes = function() {
  var uri = new URI()
  uri.removeQuery(/.*/)
  $('.scope').each(function() {
    uri.setQuery($(this).attr('name'), $(this).val())
  })
  window.location.href = uri.normalizeQuery().toString()
}

$(document).on('change', 'select.scope', applyScopes)

$(document).on('keyup', 'input[type="text"].scope', function(e) {
  if (e.key == 'Enter') {
    e.preventDefault()
    applyScopes()
  }
})

$(document).on('click', '.clear-scopes', function(e) {
  e.preventDefault()
  window.location.href = window.location.pathname
})

// Hijack rails' ujs driver
$(document).on('change', 'form.registration-form', function() {
  $(this).attr('data-remote', 'true')
  $(this).find('[type="submit"]').trigger('click')
})

$(document).on('ajax:complete', 'form.registration-form', function() {
  $(this).removeAttr('data-remote')
})

$(document).on('ajax:before', 'form.person', function() {
  $('#lookup-results').html('<i class="fa fa-spinner fa-spin fa-5x">')
})

$(document).on('refresh-select2', function() {
  $('select.select2').select2()
})
