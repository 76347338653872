import '../css'
import './map'

const updateForm = function() {
  document.querySelector('form[class*="edit_"] input.hidden[type="submit"]').click()
}

const initSelect2 = function() {
  $('#site_country_ids, #site_country_division_ids, #site_activity_ids, #site_job_ids, #site_translations').select2()
}

$(initSelect2)
$(document).on('site:update', '#site', initSelect2)
$(document).on('change', '.autoupdate', updateForm)
$(document).on('cocoon:after-remove', 'form.edit_site', updateForm)
