const loadPage = function(page) {
  var url = `/admin/lists/${page}`
  if ($(`#${page} #site`).length > 0)
    url += '?site=' + $(`#${page} #site option:selected`).val()

  $(`#${page}`).html('<div class="lead"><i class="fa fa-spinner fa-spin fa-5x"></i></div>')
  $.ajax({
    type: 'GET',
    dataType: 'script',
    url: url
  }).done(function() {
    $('.select2').select2()
  })
}

const loadCurrentPage = function() {
  if ($('.lists').length > 0) loadPage($('.lists .tab-pane.active').attr('id'))
}

$(loadCurrentPage)
$(document).on('shown.bs.tab', '.lists', loadCurrentPage)
$(document).on('change', '.tab-pane #site', function() {
  loadPage($(this).parents('.tab-pane').attr('id'))
})
