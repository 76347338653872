const toggleChoirOptions = function() {
  $('form.registration.choir .festival-choir-options').each(function() {
    $(this).toggle($(this).parent().find('.registration_active_people_festival_choir input:checkbox').is(':checked'))
  })
  $('form.registration.choir .special-music-options').each(function() {
    $(this).toggle($(this).parent().find('.registration_active_people_special_music input:checkbox').is(':checked'))
  })
  $('form.registration.choir .entertainment-night-options').each(function() {
    $(this).toggle($(this).parent().find('.registration_active_people_entertainment_night input:checkbox').is(':checked'))
  })
}

$(document).on('click', 'form.registration.choir input:checkbox', toggleChoirOptions)
$(toggleChoirOptions)
