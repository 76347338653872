const toggleNotAttending = function(boolean) {
  var attending = $(boolean)
  var notAttending = attending.parents('.form-group').next('.not-attending')
  notAttending.toggle(attending.val() == 'false')
}

const dateFromString = function(string) {
  var array = string.split('-').map(x => parseInt(x))
  array[1] -= 1
  return new Date(...array)
}

const getDateFromFields = function(el) {
  return [1, 2, 3].map(n => el.querySelector(`select[id$="${n}i"]`).value).join('-')
}

const calcAge = function(el) {
  var birthDate = dateFromString(getDateFromFields(el))
  if (isNaN(birthDate.getFullYear()))
    return -1

  var feastEnd = dateFromString($('form.registration.personal .people').attr('data-feast-end'))
  var years = feastEnd.getFullYear() - birthDate.getFullYear()

  if (birthDate.getMonth() > feastEnd.getMonth())
    years -= 1
  if (birthDate.getMonth() == feastEnd.getMonth() && birthDate.getDate() > feastEnd.getDate())
    years -= 1

  return years
}

const toggleAgeAndNameBadge = function() {
  $('form.registration.personal .well').each(function() {

    var container = this.querySelector('.registration_active_people_birth_date')
    var age = calcAge(container)
    if (age >= 0)
      container.querySelector('label').innerText = `Birth date (age ${age} at Feast)`

    var msg = age >= 18 ? 'Name <span class="text-danger">(as it will appear on your name badge)</span>' : 'Name'
    this.querySelector('.registration_active_people_name label').innerHTML = msg
  })
}

$(document).on('change', 'form.registration.personal .attending', function() {
  toggleNotAttending(this)
})

$(document).on('change', 'form.registration.personal .registration_active_people_birth_date select', function() {
  toggleAgeAndNameBadge()
})

$(function() {
  toggleAgeAndNameBadge()
  $('form.registration.personal .attending').each((i, el) => toggleNotAttending(el))
})
